export const events = [
  {
    title: "Holy Qurbana",
    date: "2024-10-20",
    time: "9am",
    description: "Followed by half yearly General Body Meeting.",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "St. Gregorios Day & Harvest Festival",
    date: "2024-11-03",
    time: "9am",
    description: "Parumala Perunal. Morning Prayer followed by Holy Qurbana",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana & Harvest Festival",
    date: "2024-11-17",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2024-12-01",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2024-12-15",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Christmas Service",
    date: "2024-12-24",
    time: "TBC",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2024-12-31",
    time: "TBC",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana & Sunday School Annual Day",
    date: "2025-01-05",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-01-19",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-02-02",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Fast of Nineveh",
    date: "2025-02-12",
    time: "TBC",
    description: "Evening Prayer followed by Holy Qurbana",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-02-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-03-02",
    time: "9am",
    description: "Annual General Body Meeting",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-03-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of Annunciation to St. Mary",
    date: "2025-03-24",
    time: "TBC",
    description: "Vachanipu Perunal. Evening Prayer followed by Holy Qurbana",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },

  {
    title: "Holy Qurbana",
    date: "2025-04-06",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-04-20",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-04",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-18",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-06-01",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-06-15",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-07-06",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-07-20",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-08-03",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-08-17",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-09-07",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-09-21",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-10-05",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-10-19",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-11-02",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-11-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-12-07",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-12-21",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
];
